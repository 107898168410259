<template>
  <div>
    <b-overlay :show="show" rounded="sm" variant="dark">
      <div>
        <b-card>
          <b-row>
            <b-col md="12" sm="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-rider
                variant="outline-primary"
                class="float-right"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="16"
                  class="bg-light-info"
                />
                <span class="align-middle ml-50">Add Rider</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- <template #cell(image)="data">
            <b-avatar
              :src="data.item.image"
              :text="avatarText(data.item.name)"
            />
          </template> -->
                <template #cell(name)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="data.item.image"
                        :text="avatarText(data.item.name)"
                      />
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ data.item.name }}
                    </span>
                    <small class="text-muted">{{
                      data.item.plate_number
                    }}</small>
                  </b-media>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <div class="text-nowrap" variant="danger">
                    <b-avatar variant="light-primary">
                      <feather-icon
                        :id="`dispatches-row-${data.item.id}-verify-icon`"
                        icon="TruckIcon"
                        class="cursor-pointer"
                        size="16"
                        @click="showDispatches(data.item.id)"
                      />
                    </b-avatar>
                    <b-tooltip
                      title="View Dispatches"
                      class="cursor-pointer"
                      :target="`dispatches-row-${data.item.id}-verify-icon`"
                    />

                    <b-avatar variant="light-danger">
                      <feather-icon
                        :id="`invoice-row-${data.item.id}-verify-icon`"
                        icon="Trash2Icon"
                        class="cursor-pointer"
                        size="16"
                        @click="deleteRider(data.item)"
                      />
                    </b-avatar>
                    <b-tooltip
                      title="Delete Rider"
                      class="cursor-pointer"
                      :target="`invoice-row-${data.item.id}-verify-icon`"
                    />

                    <b-avatar variant="light-info">
                      <feather-icon
                        :id="`edit-row-${data.item.id}-verify-icon`"
                        icon="EditIcon"
                        class="cursor-pointer"
                        size="16"
                        @click="editRider(data.item)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-edit-rider
                      />
                    </b-avatar>
                    <b-tooltip
                      title="Edit Rider"
                      class="cursor-pointer"
                      :target="`edit-row-${data.item.id}-verify-icon`"
                    />

                    <b-avatar variant="light-warning">
                      <feather-icon
                        :id="`password-row-${data.item.id}-verify-icon`"
                        icon="KeyIcon"
                        class="cursor-pointer"
                        size="16"
                        @click="resetRiderPassword(data.item)"
                      />
                    </b-avatar>
                    <b-tooltip
                      title="Reset Password"
                      class="cursor-pointer"
                      :target="`password-row-${data.item.id}-verify-icon`"
                    />
                  </div>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>

          <!--  EDIT RIDER MODAL -->
          <b-modal
            id="modal-edit-rider"
            ref="modal-edit-rider"
            centered
            title="Edit Rider Details"
            ok-only
            ok-title="Edit Rider"
            hide-footer
          >
            <div>
              <b-overlay
                :show="showRiderEditOverlay"
                rounded="sm"
                variant="dark"
              >
                <div>
                  <b-form @submit="onSubmitEditRider" @reset="onResetEditRider">
                    <b-form-group>
                      <label for="name">Name:</label>
                      <b-form-input
                        id="name"
                        type="text"
                        placeholder="Name"
                        v-model="rider.name"
                      />
                    </b-form-group>

                    <b-form-group>
                      <label for="email">Email:</label>
                      <b-form-input
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        v-model="rider.email"
                      />
                    </b-form-group>

                    <b-row>
                      <b-col>
                        <b-form-group>
                          <label for="phone">Phone:</label>
                          <b-form-input
                            id="phone"
                            type="text"
                            placeholder="Phone"
                            v-model="rider.phone"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <label for="vehicle">Vehicle:</label>
                          <b-form-input
                            id="vehicle"
                            type="text"
                            placeholder="Vehicle"
                            v-model="rider.vehicle"
                            list="vehicle-list"
                          />
                          <datalist id="vehicle-list">
                            <option>Bike</option>
                            <option>Car</option>
                          </datalist>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <label for="plate_number">Plate Number:</label>
                          <b-form-input
                            id="plate_number"
                            type="text"
                            placeholder="Plate Number"
                            v-model="rider.plate_number"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <b-col md="12" lg="12">
                        <b-button
                          class="mx-1 float-right"
                          type="submit"
                          variant="primary"
                          >Submit</b-button
                        >
                        <b-button
                          class="mx-0 float-right"
                          type="reset"
                          variant="danger"
                          >Reset</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-overlay>
            </div>
          </b-modal>

          <!-- RIDER DISPATCHES MODAL -->
          <b-modal
            id="modal-dispatches-rider"
            ref="modal-dispatches-rider"
            centered
            title="Rider Dispatches"
            hide-footer
            size="lg"
          >
            <b-row>
              <b-col xl="12" md="12">
                <dispatch-list :type="dispatch.type" :id="dispatch.id" />
              </b-col>
            </b-row>
          </b-modal>

          <!--PASSWORD RESET-->
          <b-modal
            id="modal-password-reset"
            ref="modal-password-reset"
            centered
            title="Reset Rider Password"
            ok-only
            ok-title="Reset Password"
            hide-footer
          >
            <div>
              <b-overlay
                :show="showRiderResetOverlay"
                rounded="sm"
                variant="dark"
              >
                <div>
                  <b-form
                    @submit="onSubmitPasswordReset"
                    @reset="onResetPasswordReset"
                  >
                    <b-form-group>
                      <label for="name">Name:</label>
                      <h5 class="secondary-text">{{ rider.name }}</h5>
                    </b-form-group>

                    <b-form-group>
                      <label for="email">Email:</label>
                      <h6 class="secondary-text">{{ rider.email }}</h6>
                    </b-form-group>

                    <b-row>
                      <b-col>
                        <b-form-group>
                          <label for="phone">Phone:</label>
                          <h6 class="secondary-text">{{ rider.phone }}</h6>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <label for="vehicle">Vehicle:</label>
                          <h6 class="secondary-text">{{ rider.vehicle }}</h6>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <label for="lastName">Plate Number:</label>
                          <h6 class="secondary-text">
                            {{ rider.plate_number }}
                          </h6>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group>
                          <label for="password">Create Password</label>
                          <b-form-input
                            id="password"
                            type="password"
                            placeholder="Create Password"
                            v-model="rider.password"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <label for="password_confirmation"
                            >Confirm Password</label
                          >
                          <b-form-input
                            id="password_confirmation"
                            type="password"
                            placeholder="Confirm Password"
                            v-model="rider.password_confirmation"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <b-col md="12" lg="12">
                        <b-button
                          class="mx-1 float-right"
                          type="submit"
                          variant="primary"
                          >Submit</b-button
                        >
                        <b-button
                          class="mx-0 float-right"
                          type="reset"
                          variant="danger"
                          >Reset</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-overlay>
            </div>
          </b-modal>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTooltip,
  BModal,
  BForm,
  BMedia,
  BOverlay,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { apiUrl } from "@/constants/config";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DispatchList from "./DispatchList.vue";

import { mapGetters } from "vuex";

export default {
  directives: {
    //"b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTooltip,
    BModal,
    BForm,
    DispatchList,
    BMedia,
    BOverlay,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        /* {
          key: "id",
          label: "Id",
        },
        {
          key: "image",
          label: "",
        }, */
        { key: "name", label: "Full Name", sortable: true },
        "phone",
        "email",
        "vehicle",
        { key: "dispatchesCount", label: "Dispatches", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Action" },
      ],
      items: [],
      status: [
        {
          ACTIVE: "ACTIVE",
          INACTIVE: "INACTIVE",
        },
        {
          ACTIVE: "light-success",
          INACTIVE: "light-danger",
        },
      ],
      rider: {
        name: "",
        email: "",
        phone: "",
        vehicle: "",
        plate_number: "",
        password: "",
        password_confirmation: "",
      },
      dispatch: {
        type: "rider",
        id: "",
      },
      show: false,
      showRiderEditOverlay: false,
      showRiderResetOverlay: false,
    };
  },
  setup() {
    return {
      avatarText,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters(["riderAdded"]),
  },
  watch: {
    riderAdded(val) {
      if (val && val == true) {
        this.getRiders();
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.getRiders();
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getRiders() {
      this.show = true;
      try {
        axios
          .get(apiUrl + "rider", {
            headers: {
              Authorization: "Bearer " + store.state.user.currentUser.token,
            },
          })
          .then((response) => {
            this.items = response.data.data;
            store.state.user.riderAdded = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.show = false;
          });
      } catch (e) {
        console.log(e);
      }
    },
    deleteRider(acc) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete this rider: (" +
            acc.name +
            " - " +
            acc.plate_number +
            ").",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            this.delete(acc.id);
          }
        });
    },
    delete(id) {
      try {
        axios
          .delete(apiUrl + "rider/" + id, {
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + store.state.user.currentUser.token,
            },
          })
          .then((response) => {
            this.items = this.items.filter((value) => value.id != id);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success: Rider deleted!",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + error.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: Could not initiate request!",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    editRider(acc) {
      this.rider = acc;
    },
    onSubmitEditRider(event) {
      this.showRiderEditOverlay = true;
      event.preventDefault();
      //console.log(JSON.stringify(this.rider));
      //console.log(this.rider.id);

      //post data
      axios({
        url: apiUrl + "rider/" + this.rider.id,
        data: this.rider,
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + store.state.user.currentUser.token,
        },
      })
        .then((response) => {
          //TODO: Refresh rider metrics and table
          this.$refs["modal-edit-rider"].hide();
          //console.log(JSON.stringify(response.data));
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success: " + response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + error.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.showRiderEditOverlay = false;
        });
    },
    onResetEditRider(event) {
      event.preventDefault();
      this.rider.name = "";
      this.rider.email = "";
      this.rider.phone = "";
      this.rider.vehicle = "";
      this.rider.plate_number = "";
    },
    showDispatches(rider) {
      this.dispatch.id = rider;
      this.$refs["modal-dispatches-rider"].show();
    },
    resetRiderPassword(rider) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to reset this rider's password: (" +
            rider.name +
            " - " +
            rider.plate_number +
            ").",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            this.rider = rider;
            this.$refs["modal-password-reset"].show();
          }
        });
    },
    onSubmitPasswordReset(event) {
      this.showRiderResetOverlay = true;
      event.preventDefault();
      axios({
        url: apiUrl + `rider/${this.rider.id}/reset-password`,
        data: {
          password: this.rider.password,
          password_confirmation: this.rider.password_confirmation,
        },
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + store.state.user.currentUser.token,
        },
      })
        .then((response) => {
          //TODO: Refresh rider metrics and table
          this.$refs["modal-password-reset"].hide();
          //console.log(JSON.stringify(response.data));
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success: " + response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + error.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.showRiderResetOverlay = false;
        });
    },
    onResetPasswordReset(event) {
      event.preventDefault();
      this.rider.password = "";
      this.rider.password_confirmation = "";
    },
  },
};
</script>
