var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm","variant":"dark"}},[_c('div',[(_vm.items)?_c('b-card',{staticClass:"card-rider-table",attrs:{"no-body":""}},[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.items,"responsive":"","fields":_vm.fields,"caption-top":"","small":_vm.type == 'rider'},scopedSlots:_vm._u([{key:"table-caption",fn:function(){return [_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'partner'),expression:"type == 'partner'"}],staticClass:"pl-2"},[_vm._v(" Recent Dispatches ")])]},proxy:true},{key:"cell(rider)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1"}),_c('div',[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(data.item.rider.name)+" ")]),_c('div',{staticClass:"font-small-2 text-muted"},[_vm._v(" "+_vm._s(data.item.rider.plate_number)+" ")])])],1)]}},{key:"cell(trip)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":data.item.scheduled_for != null
                    ? 'light-success'
                    : 'light-primary'}},[_c('feather-icon',{attrs:{"size":"18","icon":data.item.scheduled_for != null
                      ? 'CheckCircleIcon'
                      : 'ClockIcon'}})],1),_c('span',[_vm._v(_vm._s(data.item.scheduled_for != null ? "Instant" : "Scheduled"))])],1)]}},{key:"cell(date)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"mb-25"},[_vm._v(_vm._s(new Date(data.item.created_at).toLocaleTimeString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric", })))]),_c('span',{staticClass:"font-small-2 text-muted text-nowrap"},[_vm._v("{{ "),_c('timeago',{attrs:{"datetime":data.item.created_at}}),_vm._v(" }}")],1)])]}},{key:"cell(amount)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s("₦" + Number(data.item.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-badge',{attrs:{"pill":"","variant":data.item.status == 'DELIVERED'
                    ? 'light-success'
                    : data.item.status == 'CANCELLED'
                      ? 'light-danger'
                      : data.item.status == 'REQUESTED'
                        ? 'secondary'
                        : 'warning'}},[_vm._v(" "+_vm._s(data.item.status)+" ")])],1)]}}],null,false,244424715)})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }