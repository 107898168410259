<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="dark"
    >
      <div>
        <b-card
          v-if="items"
          no-body
          class="card-rider-table"
        >
          <b-table
            :items="items"
            responsive
            :fields="fields"
            class="mb-0"
            caption-top
            :small="type == 'rider'"
          >
            <template #table-caption>
              <h4
                v-show="type == 'partner'"
                class="pl-2"
              >
                Recent Dispatches
              </h4>
            </template>
            <!-- rider -->
            <template #cell(rider)="data">
              <div class="d-flex align-items-center">
                <!-- <b-avatar rounded size="32" variant="light-rider"> -->
                <b-avatar class="mr-1">
                  <!-- <feather-icon size="18" icon="avatarIcon" /> -->
                </b-avatar>
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.rider.name }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.item.rider.plate_number }}
                  </div>
                </div>
              </div>
            </template>

            <!-- trip -->
            <template #cell(trip)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-1"
                  :variant="
                    data.item.scheduled_for != null
                      ? 'light-success'
                      : 'light-primary'
                  "
                >
                  <feather-icon
                    size="18"
                    :icon="
                      data.item.scheduled_for != null
                        ? 'CheckCircleIcon'
                        : 'ClockIcon'
                    "
                  />
                </b-avatar>
                <span>{{
                  data.item.scheduled_for != null ? "Instant" : "Scheduled"
                }}</span>
              </div>
            </template>

            <!-- date -->
            <template #cell(date)="data">
              <div class="d-flex flex-column">
                <span class="mb-25">{{
                  new Date(data.item.created_at).toLocaleTimeString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}</span>
                <span
                  class="font-small-2 text-muted text-nowrap"
                >{{
                  <timeago :datetime="data.item.created_at" />
                  }}</span>
              </div>
            </template>

            <!-- amount -->
            <template #cell(amount)="data">
              <span class="font-weight-bolder">{{
                "₦" +
                  Number(data.item.amount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
              }}</span>
            </template>

            <!-- status -->
            <template #cell(status)="data">
              <div class="d-flex align-items-center">
                <b-badge
                  pill
                  :variant="
                    data.item.status == 'DELIVERED'
                      ? 'light-success'
                      : data.item.status == 'CANCELLED'
                        ? 'light-danger'
                        : data.item.status == 'REQUESTED'
                          ? 'secondary'
                          : 'warning'
                  "
                >
                  {{ data.item.status }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge, BCard, BTable, BAvatar, BImg, BOverlay,
} from 'bootstrap-vue'
import { apiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'

//

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BOverlay,
  },
  props: {
    type: String, // parter|rider
    id: Number, // rider id
  },

  data() {
    return {
      fields: [
        { key: 'rider', label: 'RIDER' },
        { key: 'trip', label: 'TRIP' },
        { key: 'date', label: 'DATE' },
        { key: 'amount', label: 'AMOUNT' },
        { key: 'status', label: 'STATUS' },
      ],
      items: [],
      show: false,
    }
  },

  created() {
    this.getDispatches()
  },

  methods: {
    getDispatches() {
      this.show = true
      try {
        axios
          .get(
            apiUrl
              + (this.type === 'rider'
                ? `rider/${this.id}/dispatches`
                : 'dispatch'),
            {
              headers: {
                Authorization: `Bearer ${store.state.user.currentUser.token}`,
              },
            },
          )
          .then(response => {
            this.items = response.data.data.data
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.show = false
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-rider-table ::v-deep td .b-avatar.badge-light-rider {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
