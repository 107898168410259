<template>
  <section>
    <b-row>
      <b-col cols="12">
        <riders-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from 'bootstrap-vue'
import RidersList from '@/views/RidersList.vue';

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,

    RidersList,
  },
}
</script>

<style>

</style>
